<template>
  <div
    class="fullscreen-bg"
    :style="{
      backgroundColor: $vuetify.theme.themes['light'].primary,
    }"
  >
    <div class="centered">
      <AnimatedText>
        <SvgBananas class="logo" />
      </AnimatedText>
      Loading...
      <br />
      <span>( Initializing Data )</span>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss">
.fullscreen-bg {
  position: fixed;
  width: 100%;
  height: 100%;

  text-align: center;

  div.centered {
    width: 50%;
    margin: auto;
    position: relative;
    top: 33%;
    font-size: 2rem;
    color: rgba(0, 0, 0, 0.5);
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
    font-weight: bold;

    span {
      font-size: 1rem;
    }
  }

  .logo {
    width: 10rem;
    height: 10rem;
    margin-left: -2rem;
  }
}
</style>
