<template>
  <SplashScreen v-if="showSplashScreen" />
  <VApp v-else>
    <VAppBar
      fixed
      app
      :style="{ background: $vuetify.theme.current.colors.primary }"
    >
      <div id="logo">
        <NuxtLink to="/">
          <SvgWorld />
        </NuxtLink>
      </div>
      <v-spacer />
      <AppTitle />
      <v-spacer />
      <NuxtLink v-if="isLoggedIn" to="/invite">
        <VIcon>
          {{ mdiQrcode }}
        </VIcon>
      </NuxtLink>
      &nbsp;
      <AccountLink :is-logged-in="isLoggedIn" />
    </VAppBar>
    <VMain>
      <VContainer :fluid="true" class="adjust-for-footer-navbar">
        <PWANotifications />
        <ErrorCard v-if="error" :error="error" />
        <div v-if="isOnline === false">
          <VAlert type="warning">
            You seem to be offline. Are you connected to the internet?
          </VAlert>
          <br />
        </div>
        <LoadingIndicator :loading="pageLoading" />
        <NuxtErrorBoundary @error="onError">
          <NuxtPage />
        </NuxtErrorBoundary>
      </VContainer>
    </VMain>
    <Snackbar />
    <VFooter id="footer" class="default-layout-footer" fixed padless>
      <BottomNavigation :is-logged-in="isLoggedIn" />
    </VFooter>
  </VApp>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useAppStore, useFetchUser, useSupabaseClient } from '#imports'
import { type AuthChangeEvent, type Session } from '@supabase/gotrue-js'
import { useOnline } from '@vueuse/core'
import { captureException } from '@sentry/core'
import { mdiQrcode } from '@mdi/js'

useHead({
  meta: [
    {
      property: 'ir-site-verification-token',
      content: '1508969210',
    },
  ],
})

const isOnline = useOnline()
const supabase = useSupabaseClient()

const isLoggedIn = ref<boolean>(false)
const showSplashScreen = ref<boolean>(true)
const error = ref<Error | null>(null)

const nuxtApp = useNuxtApp()
const pageLoading = ref<boolean>(false)
nuxtApp.hook('page:start', () => {
  pageLoading.value = true
})
nuxtApp.hook('page:finish', () => {
  pageLoading.value = false
})

const workbox = await window.$workbox
if (workbox) {
  workbox.addEventListener('installed', (event) => {
    // If we don't do this we'll be displaying the notification after the initial installation, which isn't perferred.
    if (event.isUpdate) {
      // whatever logic you want to use to notify the user that they need to refresh the page.
      console.log('app needs update')
    }
  })
}

supabase.auth.onAuthStateChange(
  (_event: AuthChangeEvent, session: Session | null) => {
    if (_event === 'SIGNED_IN' && session) {
      setLoggedIn(true)
    } else if (_event === 'SIGNED_OUT') {
      setLoggedIn(false)
    }
  },
)

const store = useAppStore()
evaluateAuthState()

async function evaluateAuthState() {
  const { data, error } = await supabase.auth.getSession()
  if (data.session) {
    store.authUser = data.session.user
    const user = await useFetchUser(store.authUser!.id)
    store.user = user
    setLoggedIn(true)
  } else {
    store.user = null
    setLoggedIn(false)
  }

  showSplashScreen.value = false
}

function setLoggedIn(value: boolean) {
  isLoggedIn.value = value
}

function onError(unhandledError: Error) {
  console.error(unhandledError)
  error.value = unhandledError
  captureException(unhandledError)
}
</script>

<style lang="scss">
$footer-navbar-height: 60px;

#logo {
  margin-left: 1rem;
}

#account-icon {
  margin-right: 1rem;
}

.adjust-for-footer-navbar {
  padding-bottom: $footer-navbar-height;
}

.default-layout-footer {
  background: transparent !important;

  .row {
    margin: 1rem;
  }
}
</style>
