<template>
  <ClientOnly>
    <VCard
      v-if="$pwa.needRefresh"
      color="primary"
      class="$pwa-alert"
      :prepend-icon="mdiUpdate"
    >
      <template #title> Update available! </template>
      <VCardText>
        We've been busy! Please reload the app soon to ensure proper
        functionality.
      </VCardText>
      <VCardActions>
        <VSpacer />
        <VBtn
          variant="elevated"
          color="grey-lighten-3"
          @click="$pwa.updateServiceWorker()"
        >
          Reload App!
        </VBtn>
        <VSpacer />
      </VCardActions>
    </VCard>

    <VCard
      v-if="
        $pwa?.showInstallPrompt && !$pwa?.offlineReady && !$pwa?.needRefresh
      "
      color="primary"
      class="$pwa-alert"
      :prepend-icon="mdiCellphoneArrowDown"
    >
      <template #title> This app can be installed! </template>
      <VCardText>
        This Web-App can be installed on your home-screen for a more app-like
        experience.
      </VCardText>
      <VCardActions>
        <VSpacer />
        <VBtn variant="elevated" color="grey-lighten-3" @click="$pwa.install()">
          Install App!
        </VBtn>
        <VBtn @click="$pwa.cancelInstall()"> Never mind! </VBtn>
        <VSpacer />
      </VCardActions>
    </VCard>
  </ClientOnly>
</template>

<script setup lang="ts">
import { mdiUpdate, mdiCellphoneArrowDown } from '@mdi/js'

const { $pwa, $toaster } = useNuxtApp()

onMounted(() => {
  if ($pwa && $pwa.offlineReady) {
    $toaster.show({ title: 'App ready to work offline!', color: 'secondary' })
  }
})
</script>

<style scoped lang="scss">
.pwa-alert {
  margin-bottom: 1.5rem;
}
</style>
