<template>
  <div class="rainbow">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AnimatedText',
}
</script>

<style lang="scss">
.rainbow {
  svg {
    animation-name: ckw;
    animation-duration: 5s;
    animation-iteration-count: infinite;
  }
}

@keyframes ckw {
  0% {
    transform: rotate(-12deg);
  }
  50% {
    transform: rotate(36deg);
  }
  100% {
    transform: rotate(-12deg);
  }
}
</style>
