<template>
  <VCard :prepend-icon="mdiBug" color="error" variant="tonal">
    <template #title> Oh no, it crashed... </template>
    <VCardText>{{ error.stack }}</VCardText>
  </VCard>
</template>

<script lang="ts" setup>
import { mdiBug } from '@mdi/js'

interface Props {
  error: Error | null
}
const props = defineProps<Props>()

console.error('error', props.error)
</script>

<style scoped></style>
