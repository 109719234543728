<template>
  <VSnackbar
    v-for="(toast, index) in toasts"
    :key="index"
    :color="toast.color"
    :model-value="true"
    :style="{ 'margin-bottom': calcMargin(index) }"
    transition="slide-y-reverse-transition"
    :timeout="-1"
    @click="hideToast(toast.title)"
  >
    {{ toast.title }}
  </VSnackbar>
</template>

<script setup lang="ts">
import { type Toast } from '~/stores/notification'

const store = useNotificationStore()

const toasts = ref<Toast[]>([])

store.$subscribe(
  () => {
    toasts.value = [...store.toasts.values()]
    toasts.value.forEach((toast) => {
      setTimeout(() => hideToast(toast.title), 3000)
    })
  },
  { detached: true },
)

function calcMargin(index: number) {
  return index * 60 + 60 + 'px'
}

function hideToast(title: string) {
  store.toasts.delete(title)
}
</script>
