<template>
  <VBottomNavigation
    :style="{ background: $vuetify.theme.current.colors.primary }"
  >
    <VBtn v-for="(item, i) in bottomNavItems" :key="i" :to="item.to">
      <VIcon v-if="item.icon">
        {{ item.icon }}
      </VIcon>
      <span v-else>{{ item.title }}</span>
    </VBtn>
    <VSpacer />
    <VMenu>
      <template #activator="{ props }">
        <VBtn icon v-bind="props">
          <VIcon>
            {{ mdiDotsVertical }}
          </VIcon>
        </VBtn>
      </template>
      <VList>
        <VListItem
          v-for="(item, i) in dotNavItems"
          :key="i"
          :to="item.to"
          :prepend-icon="item.icon"
        >
          <VListItemTitle>
            {{ item.title }}
          </VListItemTitle>
        </VListItem>
      </VList>
    </VMenu>
  </VBottomNavigation>
</template>

<script setup lang="ts">
import {
  mdiAccountMultiple,
  mdiAirplaneTakeoff,
  mdiBug,
  mdiCampfire,
  mdiCash,
  mdiCogs,
  mdiDotsVertical,
  mdiEarth,
  mdiInformation,
} from '@mdi/js'

const props = defineProps({
  isLoggedIn: { type: Boolean, required: true, default: false },
})

const items: NavItem[] = [
  {
    icon: '',
    title: 'Welcome',
    to: '/',
    private: false,
    onlyPublic: true,
  },
  {
    icon: '',
    title: 'Login',
    to: '/sign-in',
    private: false,
    onlyPublic: true,
  },
  {
    icon: '',
    title: 'Sign Up',
    to: '/sign-up',
    private: false,
    onlyPublic: true,
  },
]

const itemsLoggedIn: NavItem[] = [
  {
    icon: mdiEarth,
    title: 'World Map',
    to: '/world-map',
    private: true,
    onlyPublic: false,
  },
  {
    icon: mdiAirplaneTakeoff,
    title: 'My Trips',
    to: '/my-trips',
    private: true,
    onlyPublic: false,
  },
  {
    icon: mdiAccountMultiple,
    title: 'My Friends',
    to: '/my-friends',
    private: true,
    onlyPublic: false,
  },
  /*{
    icon: mdiCampfire,
    title: 'Tribes',
    to: '/tribe/list',
    private: true,
    onlyPublic: false,
  },*/
]

const itemAbout = {
  icon: mdiInformation,
  title: 'About',
  to: '/about',
  private: false,
  onlyPublic: false,
}

const itemSettings = {
  icon: mdiCogs,
  title: 'Settings',
  to: '/settings',
  private: true,
  onlyPublic: false,
}

const itemDebug = {
  icon: mdiBug,
  title: 'Debug',
  to: '/debug',
  private: false,
  onlyPublic: false,
}

const itemTips = {
  icon: mdiCash,
  title: 'Recommendations',
  to: '/recommendations',
  private: false,
  onlyPublic: false,
}

const dotItems: NavItem[] = [itemAbout, itemTips, itemSettings, itemDebug]

const dotItemsLoggedIn: NavItem[] = [
  itemAbout,
  itemTips,
  itemSettings,
  itemDebug,
]

const bottomNavItems = computed(() =>
  props.isLoggedIn ? itemsLoggedIn : items,
)

const dotNavItems = computed(() =>
  props.isLoggedIn ? dotItemsLoggedIn : dotItems,
)
</script>
