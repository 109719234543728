<template>
  <div class="title">
    <h1>{{ appStore.meta.title }}</h1>
  </div>
</template>

<script lang="ts" setup>
import { useAppStore } from '#imports'

const appStore = useAppStore()
</script>

<style lang="scss">
.title {
  h1 {
    font-size: 1.7rem;
  }
}
</style>
